<script setup lang="ts">
import {computed} from "vue";
import {cdsBackend} from "@/backend/cds.backend.ts";

const props = defineProps<{value: number | null}>()
const rating = computed(() => cdsBackend.getRatingByValue(props.value))

const severity = computed(() => {
  const ratingVal = rating?.value?.value;
  if (ratingVal === 0) return 'danger'

  if (!ratingVal)
    return 'secondary';

  if (ratingVal === 1) return 'warn'
  if (ratingVal === 2) return 'info'
  if (ratingVal === 3) return 'success'
  if (ratingVal === 4) return 'primary'
  return 'secondary'
})
</script>

<template>
  <Tag :value="rating.label" v-if="rating" :severity="severity" :key="rating.value || 0"/>
</template>
