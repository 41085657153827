<script setup lang="ts">
import {computed} from "vue";

const {value} = defineProps(['value'])
const MAX_LENGTH = 25
const tag = computed(() => {
  return value.length > MAX_LENGTH ? `${value.slice(0, MAX_LENGTH)}...` : value
})
</script>

<template>
  <Tag :value="`#${tag}`" severity="info" v-tooltip.top="value.length > MAX_LENGTH ? value : ''" class="!text-xs"/>
</template>
