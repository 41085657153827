import {request} from "@/backend/backend_util.ts";

export type Rating = {
    value: number | null,
    label?: string,
    legend?: string,
}


export type Assessment = {
    [key: string]: number | null
}

export type AssessmentObj = {
    data: Assessment
}

export type AssessmentResponse = {
    current: AssessmentObj,
    future: AssessmentObj
}

const aoes = {
    product: 'Product Decisions',
    design: 'Design',
    backend: 'Backend',
    ios: 'iOS',
    web: 'Web',
    qa: 'QA',
    data: 'Data',
    infrastructure: 'Infrastructure'
} as {[key: string]: string}

const ratings = [
    {value: null, label: 'n/a', legend: 'Not Applicable'},
    {value: 0, label: 'None', legend: 'Can\'t do anything'},
    {value: 1, label: 'Minimal', legend: 'Can do minor things, like change a copy or sorting'},
    {value: 2, label: 'Some', legend: 'Can do some things. Minor features and tweaks; Fix a non-complicated bug. Add a field to API response; '},
    {value: 3, label: 'Most', legend: 'Can do most things. Major features and tweaks; Can do most of the things. Add new stuff within boundaries; Combine from existing elements; Introduce new elements similar to existing ones'},
    {value: 4, label: 'Full', legend: 'Strategic, architectural, fundamental decisions and changes; Add new elements'}
]


export const cdsBackend = {
    getAssessment: request<AssessmentResponse>('GET', '/cds/self'),
    updateAssessment: (payload: AssessmentResponse) => request<AssessmentResponse>('POST', '/cds/self')(payload),
    getAoes: () => aoes,
    getRatings: () => ratings,
    getRatingByValue: (value: number | null) => ratings.find(r => r.value === value),
}