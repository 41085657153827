<script setup lang="ts">

import AppPage from "@/components/AppPage.vue";
import {computed, onMounted, ref} from "vue";
import Debug from "@/components/Debug.vue";
import CDSAssessment from "@/components/cds/CDSAssessment.vue";
import {Assessment, cdsBackend} from "@/backend/cds.backend.ts";
import {debounce} from "throttle-debounce";
import CDSPill from "@/components/cds/CDSPill.vue";

const current = ref<Assessment>({})
const future = ref<Assessment>({})
const aoes = cdsBackend.getAoes()
const ratings = cdsBackend.getRatings()

const hasCurrentFilled = computed(() => {
  const currentKeys = Object.keys(current.value)
  return Object.keys(aoes).every(aoe => currentKeys.includes(aoe))
})

const futureIsThere = ref(false)

onMounted(async () => {
  const response = await cdsBackend.getAssessment();
  console.log(response)
  current.value = response.current?.data || {}
  future.value = response.future?.data || {}
})

const debounceStore = debounce(500, store)

async function store() {
  await cdsBackend.updateAssessment({
    // @ts-ignore
    current: current.value,
    // @ts-ignore
    future: future.value
  })
  console.log("Stored")
}

function change() {
  console.log("Changed")
}

</script>

<template>
  <AppPage title="CDS" :loaded="true">

    <div class="flex flex-row justify-between">
      <div class="flex flex-col gap-2">
        <h1>Welcome, Adventurer</h1>
        <h2>Now</h2>
        <div class="flex flex-row justify-between">
          <div>
            <p>Let's start with assessing how things are now.</p>
            <p>How do you assess your ability to do things in different areas? </p>
            <p>* It's ok to have Nones in many areas </p>
          </div>
        </div>


        <CDSAssessment v-model="current" :aoes="aoes" @update:modelValue="debounceStore"/>

          <Button label="Take me to the future"
                  :disabled="!hasCurrentFilled"
                  v-tooltip.top="!hasCurrentFilled ? 'Finish the current reality assessment first' : ''"
                  @click="futureIsThere = true"
                  v-if="!futureIsThere"
          />
        <div v-if="futureIsThere" class="mt-6">
          <h2>Future</h2>
          <p>
            How would you like things to be in an ideal world?
          </p>
          <p>
            Don't worry if it's possible in the current reality.
          </p>
          <p>
            We'll go extra mile to make it happen.
          </p>

          <CDSAssessment v-model="future" :aoes="aoes" class="mt-4"  @update:modelValue="debounceStore"/>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center">
        <p class="font-medium">Legend</p>
        <ul>
          <li v-for="rating in ratings"  class="text-sm">
            <div class="flex flex-row w-[35rem] mb-4 gap-2 items-start">

              <CDSPill :value="rating.value" class="min-w-[5rem]"/>
              <span>{{rating.legend}}</span>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <Debug :data="{current, future}" expanded/>
  </AppPage>
</template>

<style scoped>
@reference "tailwindcss";

h1 {
  @apply text-3xl font-bold;
}

h2 {
  @apply text-2xl font-bold;
}
</style>