<script setup lang="ts">
import CDSPill from "@/components/cds/CDSPill.vue";
import {cdsBackend} from "@/backend/cds.backend.ts";

const aoes = cdsBackend.getAoes();
const ratings = cdsBackend.getRatings();

const model = defineModel<{[key: string]: number | null}>()

</script>

<template>
  <div class="grid grid-cols-4 gap-4">
    <div v-for="aoe in Object.keys(aoes)" class="flex flex-col gap-2">
      <label class="font-medium text-muted-color">{{aoes[aoe]}}</label>
      <Select v-if="model" v-model="model[aoe]" :options="ratings" optionLabel="label" scrollHeight="" optionValue="value"
              @change="$emit('update:modelValue', model)"
              class="w-[9rem]"
      >
        <template #option="{option}">
          <CDSPill :value="option.value"/>
        </template>
        <template #value="{value}">
          <CDSPill :value="value" v-if="value !== undefined"/>
          <span v-else>Not Rated</span>
        </template>
      </Select>
    </div>
  </div>

</template>
